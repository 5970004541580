import { memo, useContext } from "react";
import style from "styles/screenshots.module.css";
import screenshot1 from "public/image/screen1.webp";
import screenshot2 from "public/image/screen2.webp";
import screenshot3 from "public/image/screen3.webp";
import screenshot4 from "public/image/screen4.webp";
import ThemeContext from '../../../../context/themeContext'

const Screenshots = () => {

    const { popUpScreenActive, setPopUpScreenActive, setNumber } = useContext(ThemeContext);

    const handleOnClickOne = () => {
        setNumber(0)
        setPopUpScreenActive(!popUpScreenActive)
    }
    const handleOnClickTwo = () => {
        setNumber(1)
        setPopUpScreenActive(!popUpScreenActive)
    }
    const handleOnClickThree = () => {
        setNumber(3)
        setPopUpScreenActive(!popUpScreenActive)
    }
    const handleOnClickFour = () => {
        setNumber(2)
        setPopUpScreenActive(!popUpScreenActive)
    }

    return (
        <div className={style.screenshots}>
            <div className={style.container}>
                <div
                  data-aos="fade-down"
                  data-aos-delay={50}
                  data-aos-once="true"
                  className={style.block}>
                    <button aria-label="picture 1" onClick={handleOnClickOne} className={style.picture1}>
                        <img
                            src={screenshot1.src}
                            alt=""
                        />
                    </button>
                    <button aria-label="picture 2" onClick={handleOnClickTwo} className={style.picture2}>
                        <img
                            src={screenshot2.src}
                            alt=""
                        />
                    </button>
                    <button aria-label="picture 3" onClick={handleOnClickThree} className={style.picture4}>
                        <img
                            src={screenshot3.src}
                            alt=""
                        />
                    </button>
                    <button aria-label="picture 4" onClick={handleOnClickFour} className={style.picture3}>
                        <img
                            src={screenshot4.src}
                            alt=""
                        />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default memo(Screenshots);

